import { Checkbox, CheckboxProps } from './Checkbox';
import { useController, useFormContext } from 'react-hook-form';
import React from 'react';

export const FormCheckbox = ({
  name,
  label,
  subtitleLink,
  description,
}: Omit<CheckboxProps, 'isChecked' | 'onChange'> & {
  control?: any;
}): JSX.Element => {
  const { control } = useFormContext();
  const { field } = useController({
    name: name,
    control,
  });

  return (
    <Checkbox
      isChecked={field.value}
      name={name}
      label={label}
      subtitleLink={subtitleLink}
      onChange={e => field.onChange(e.target.checked)}
      description={description}
    />
  );
};
