import {
  Box,
  FormControl,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ColorInput } from '@components/common';
import { Controller, useController, useFormContext } from 'react-hook-form';
import React from 'react';

type AvatarForegroundRadioGroupSubLabels = {
  initials: string;
  silhouette: string;
};

type AvatarForegroundRadioGroupProps = {
  type: 'org' | 'user';
};

const USER_SUBLABEL_TEXTS: AvatarForegroundRadioGroupSubLabels = {
  initials: 'Display the user’s initials in the foreground.',
  silhouette: 'Display a generic user silhouette in the foreground.',
};

const ORG_SUBLABEL_TEXTS: AvatarForegroundRadioGroupSubLabels = {
  initials: 'Display the organization’s initial in the foreground.',
  silhouette: 'Display a generic organization silhouette in the foreground.',
};

const subLabels = { user: USER_SUBLABEL_TEXTS, org: ORG_SUBLABEL_TEXTS };

export const AvatarForegroundRadioGroup = ({
  type,
}: AvatarForegroundRadioGroupProps): JSX.Element => {
  const { watch, formState, control, resetField } = useFormContext();
  const { field } = useController({ name: `${type}.fgType`, control });
  const { errors } = formState;
  const values = watch();
  return (
    <RadioGroup
      onChange={nextValue => field.onChange(nextValue)}
      value={field.value}
    >
      <Stack direction='column' spacing={4}>
        <Radio value='initials' alignItems={'flex-start'}>
          <Text textStyle='md-medium'>Initials</Text>
          <Text textStyle='sm-medium' fontWeight='normal' color='gray.500'>
            {subLabels[type].initials}
          </Text>
        </Radio>
        {values[type].fgType === 'initials' && (
          <Box pl={6}>
            <FormControl isInvalid={!!errors?.[type]?.fgColor} width={'195px'}>
              <Controller
                control={control}
                name={`${type}.fgColor`}
                render={({ field: { onChange, value, name } }) => {
                  const handleChange = e => {
                    onChange(e.slice(1));
                  };
                  return (
                    <ColorInput
                      name={name}
                      value={`#${value}`}
                      onChange={handleChange}
                    />
                  );
                }}
              />
            </FormControl>
          </Box>
        )}
        <Radio value='silhouette' alignItems={'flex-start'}>
          <Text textStyle='md-medium'>Silhouette</Text>
          <Text textStyle='sm-medium' fontWeight='normal' color='gray.500'>
            {subLabels[type].silhouette}
          </Text>
        </Radio>
        {values[type].fgType === 'silhouette' && (
          <Box pl={6}>
            <FormControl isInvalid={!!errors?.[type]?.fgColor} width={'195px'}>
              <Controller
                control={control}
                name={`${type}.fgColor`}
                render={({ field: { onChange, value, name } }) => {
                  const handleChange = e => {
                    onChange(e.slice(1));
                  };
                  return (
                    <ColorInput
                      name={name}
                      value={`#${value}`}
                      onChange={handleChange}
                    />
                  );
                }}
              />
            </FormControl>
          </Box>
        )}
        <Radio
          value='none'
          onChange={() => resetField(`${type}.fgColor`)}
          alignItems={'flex-start'}
        >
          <Text textStyle='md-medium'>None</Text>
        </Radio>
      </Stack>
    </RadioGroup>
  );
};
