import React from 'react';
import { BaseSchema } from '@types';

import { DumbPagination } from '@components/common';

import {
  Table as TableWrapper,
  Cell,
  HeadCell,
  Head,
  Row,
  Body,
  EmptyHeadCell,
} from './BaseTable';

type TableProps<T> = {
  data: T[];
  tableSchema?: BaseSchema<T>;
  pagination?: React.ReactNode;
  emptyTableFallback?: JSX.Element;
  rowActions?: (data: T) => JSX.Element;
  onRowClicked: (data: T) => void;
};

const Table = <T extends { id: string }>({
  data,
  tableSchema,
  pagination,
  emptyTableFallback,
  rowActions,
  onRowClicked,
}: TableProps<T>): JSX.Element => {
  const tableHeaders = tableSchema.map(({ header }) => header);
  const tableCells = tableSchema.map(({ cell, key, tdProps }) => ({
    cell,
    key,
    tdProps,
  }));

  return (
    <>
      <TableWrapper>
        <Head>
          <Row isDisabled>
            {tableHeaders.map(header => (
              <HeadCell key={header}>{header}</HeadCell>
            ))}
            {rowActions && <EmptyHeadCell />}
          </Row>
        </Head>
        <Body>
          {data.length === 0 ? (
            emptyTableFallback
          ) : (
            <>
              {data.map((row, index) => {
                const isLast = index === data.length - 1;

                return (
                  <Row key={row.id} onClick={() => onRowClicked(row)}>
                    {tableCells.map(({ cell, key, tdProps }) => (
                      <Cell
                        isLast={isLast}
                        key={`${row.id}-${key}`}
                        {...tdProps}
                      >
                        {cell(row)}
                      </Cell>
                    ))}
                    <Cell
                      isNumeric
                      onClick={ev => ev.stopPropagation()}
                      borderTopRightRadius='md'
                      borderBottomRightRadius='md'
                      isLast={isLast}
                      width={12}
                    >
                      {rowActions?.(row)}
                    </Cell>
                  </Row>
                );
              })}
            </>
          )}
        </Body>
      </TableWrapper>
      {pagination}
    </>
  );
};

Table.Pagination = DumbPagination;

export default Table;
