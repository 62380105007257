import React from 'react';
import { removeAutoComplete } from '@utils';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { OAuthProviderSettings } from '@types';

const INPUT_ID = 'additional_scope_input';

interface AdditionalScopeInputProps {
  label: string;
  description: React.ReactNode;
  oauthProviderSettings: OAuthProviderSettings;
  customProfile: boolean;
}

function removeScope(arr: string[], scope: string): string[] {
  return arr.filter(item => scope !== item);
}

function addScopes(arr: string[], scopeStr: string): string[] {
  const scopes = scopeStr
    .trim()
    .split(/\s+/)
    .filter(item => item.length > 0);
  return Array.from(new Set([...arr, ...scopes]));
}

export function AdditionalScopeInput({
  label,
  description,
  oauthProviderSettings,
  customProfile,
}: AdditionalScopeInputProps): JSX.Element {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [input, setInput] = React.useState('');

  const baseScopes = oauthProviderSettings.base_scopes || [];
  const initialAdditionalScopes = oauthProviderSettings.additional_scopes || [];

  const additionalScopesSupported = !['oauth_apple', 'oauth_twitter'].includes(
    oauthProviderSettings.strategy,
  );

  return (
    <>
      <FormControl
        isInvalid={!!errors.additional_scopes}
        {...removeAutoComplete(INPUT_ID)}
      >
        <Controller
          control={control}
          name='additional_scopes'
          defaultValue={initialAdditionalScopes}
          render={({ field: { onChange, value } }) => {
            // Don't know why 'value' can be null even if we provide a default value #smh
            const currentAdditionalScopes = value || [];
            const currentTotalScopes = [
              ...baseScopes,
              ...currentAdditionalScopes,
            ];

            return (
              <VStack align='baseline' spacing='2'>
                <FormLabel fontSize='sm' mb='0.5'>
                  {label}
                </FormLabel>

                {customProfile && additionalScopesSupported && (
                  <>
                    <Text color='gray.500' textStyle='md-normal' w='full'>
                      {description}
                    </Text>

                    <InputGroup
                      variant='addonAction'
                      borderWidth='1px'
                      borderRadius='md'
                      display='flex'
                    >
                      <Input
                        type='text'
                        name={INPUT_ID}
                        value={input}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setInput(e.target.value);
                        }}
                        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            onChange(addScopes(currentAdditionalScopes, input));
                            setInput('');
                          }
                        }}
                      />

                      <InputRightAddon>
                        <Button
                          variant='link'
                          onClick={() => {
                            onChange(addScopes(currentAdditionalScopes, input));
                            setInput('');
                          }}
                        >
                          Add
                        </Button>
                      </InputRightAddon>
                    </InputGroup>
                  </>
                )}

                {currentTotalScopes.length > 0 ? (
                  <Box>
                    {customProfile && additionalScopesSupported && (
                      <Text textStyle='sm-normal' mb='2'>
                        Current scopes
                      </Text>
                    )}

                    {baseScopes.map(baseScope => (
                      <Tag key={baseScope} colorScheme='gray' mr='2' mb='2'>
                        <TagLabel>{baseScope}</TagLabel>
                      </Tag>
                    ))}

                    {customProfile &&
                      currentAdditionalScopes.map(tag => (
                        <Tag key={tag} colorScheme='gray' mr='2' mb='2'>
                          <TagLabel>{tag}</TagLabel>
                          <TagCloseButton
                            onClick={() =>
                              onChange(
                                removeScope(currentAdditionalScopes, tag),
                              )
                            }
                          />
                        </Tag>
                      ))}
                  </Box>
                ) : (
                  <Text textStyle='sm-normal' mb='2'>
                    None specified
                  </Text>
                )}
              </VStack>
            );
          }}
        />

        <FormErrorMessage>{errors.additional_scopes?.message}</FormErrorMessage>
      </FormControl>
    </>
  );
}
