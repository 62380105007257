import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Code,
  Flex,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ReadonlyInputBox } from '@components/common';
import { CodeIcon, PencilIcon } from '@heroicons/react/solid';
import React from 'react';
import { Modal } from '@components/common';
import { editor } from 'monaco-editor';
import Editor, { useMonaco, OnChange } from '@monaco-editor/react';
import { exampleJsonData, isExampleData } from '@components/jsonEditor/utils';

export type EditableMetadataFieldProps = {
  label: string;
  value: Record<string, unknown>;
  visibilityIcon: React.FunctionComponent;
  visibilityDescription: string | JSX.Element;
  saveHandler: (data: Record<string, unknown>) => void;
  maxSize?: number;
};

/** We are using here the MODEL_ID as the unique identifier for our editor.
 * If we are working with multiple editors at the same time, each one has to
 * have a unique identifier as well, to separate them from each other and apply different
 * configurations.
 *
 * We have also declared a new schema bellow and inside the `useEffect`.In order for the schema
 * to be applied it has to be associated with the model uri of our Editor. We do that by assigning
 * the `fileMatch` the same `uri` as that in our `Editor`.
 * If have multiple editors and you want the shcema to applied in all of them you can use `*`.
 */
const MODEL_ID = 'clerk-metadata';

const editorOptions: editor.IStandaloneEditorConstructionOptions = {
  formatOnType: true,
  formatOnPaste: true,
  autoIndent: 'full',
  codeLens: false,
  minimap: { enabled: false },
  padding: { top: 16, bottom: 16 },
  scrollBeyondLastLine: false,
  fixedOverflowWidgets: true,
  // hide *all* suggestions
  quickSuggestions: false,
  acceptSuggestionOnEnter: 'off',
  acceptSuggestionOnCommitCharacter: false,
  tabCompletion: 'off',
  // Set height
  fontSize: 16,
  dimension: { height: 320, width: 0 },
};

export const EditableMetadataField = ({
  label,
  value,
  visibilityIcon,
  visibilityDescription,
  saveHandler,
  maxSize = 4096,
}: EditableMetadataFieldProps): JSX.Element => {
  const monaco = useMonaco();
  let decoratedValue = (
    <Flex align='center'>
      <Text textStyle='md-normal' color='gray.500'>
        (none)
      </Text>
    </Flex>
  );
  let initValue = exampleJsonData;
  const jsonString = JSON.stringify(value, null, '\t');
  if (value && jsonString !== '{}') {
    decoratedValue = (
      <Code flex={1} noOfLines={5} mr={4} py={1} pl={4} lineHeight={1.5}>
        {jsonString}
      </Code>
    );
    initValue = jsonString;
  }
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [editorValue, setEditorValue] = React.useState(initValue);
  const [error, setError] = React.useState(null);
  const editorRef = React.useRef<editor.ICodeEditor>(null);

  React.useEffect(() => {
    if (monaco) {
      monaco.editor.createModel;
      monaco?.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        schemas: [
          {
            uri: 'clerk-metadata-schema',
            fileMatch: [MODEL_ID],
            schema: {
              type: 'object',
            },
          },
        ],
      });
    }
  }, [monaco]);

  React.useEffect(() => {
    return () => editorRef.current?.dispose();
  }, []);

  const handleMount = (editor: editor.IStandaloneCodeEditor) => {
    editorRef.current = editor;
    editor.focus();
    setError(null);
    setEditorValue(initValue);
  };

  const handleValidate = (markers: editor.IMarker[]) => {
    const marker = markers[0];
    if (marker) {
      if (!marker.code) {
        return setError(`${marker.message}`);
      }
      return setError(
        `Error on line ${marker.startLineNumber}: ${marker.message}`,
      );
    }
    const size = getEditorValue().length;
    if (size > maxSize) {
      return setError(
        `Max allowed size is ${maxSize} bytes (current size: ${size})`,
      );
    }
    setError(null);
  };

  const getEditorValue = () => {
    return isExampleData(editorValue) || !editorValue ? '{}' : editorValue;
  };

  const onSubmitMetadata = () => {
    saveHandler(JSON.parse(getEditorValue()));
    onClose();
  };

  const onChangeData: OnChange = value => {
    setEditorValue(value);
  };

  return (
    <>
      <ReadonlyInputBox leftIcon={CodeIcon} label={label}>
        <Flex justify='space-between'>
          {decoratedValue}
          <Button onClick={onOpen} w='30'>
            <Icon as={PencilIcon} />
            &nbsp;Edit
          </Button>
        </Flex>
      </ReadonlyInputBox>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size='2xl'
        title={`Edit ${label.toLowerCase()} metadata`}
      >
        <Modal.CloseButton />
        <Modal.Body>
          <Alert status='info' alignItems='top' mb={4} rounded='base'>
            <AlertIcon as={visibilityIcon} />
            <Box flex='1'>
              <AlertTitle>Visibility</AlertTitle>
              <AlertDescription>{visibilityDescription}</AlertDescription>
            </Box>
          </Alert>
          <Box
            height={editorOptions.dimension.height}
            rounded='base'
            overflow='hidden'
          >
            <Editor
              theme='vs-dark'
              language='json'
              defaultValue={initValue}
              options={editorOptions}
              onMount={handleMount}
              onValidate={handleValidate}
              onChange={onChangeData}
              path={MODEL_ID}
            />
          </Box>
          {error && (
            <Alert status='error' mt={2} rounded='base'>
              <AlertIcon />
              <AlertTitle>{error}</AlertTitle>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer flexDir='row-reverse'>
          <Button disabled={error} onClick={onSubmitMetadata}>
            Save
          </Button>
          <Button variant='ghost' onClick={onClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
