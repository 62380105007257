import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useExitProtection = (enable: boolean, cb: () => void) => {
  const router = useRouter();

  useEffect(() => {
    const handleWindowClose = (e: BeforeUnloadEvent) => {
      if (!enable) {
        return;
      }
      e.preventDefault();
      e.returnValue = '';
    };

    // Check this if we need an async handler in the future
    // https://github.com/vercel/next.js/discussions/32231#discussioncomment-2033546
    const handleBrowseAway = () => {
      if (!enable || cb()) {
        return;
      }

      router.events.emit('routeChangeError');
      throw 'Ignore this error as it will not show in production and is intended.';
    };

    window.addEventListener('beforeunload', handleWindowClose);
    router.events.on('routeChangeStart', handleBrowseAway);

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
      router.events.off('routeChangeStart', handleBrowseAway);
    };
  }, [enable, router.events]);
};
