import React from 'react';

import {
  Box,
  Table as ChakraTable,
  TableCellProps,
  Tbody,
  Td as ChakraTd,
  Th as ChakraTh,
  Thead,
  Tr,
} from '@chakra-ui/react';

export const Table = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Box overflowX='auto'>
      <ChakraTable>{children}</ChakraTable>
    </Box>
  );
};

export const Head = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <Thead>{children}</Thead>;
};

export const HeadCell = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <ChakraTh borderColor='gray.200' color='gray.500' textStyle='sm-normal'>
      {children}
    </ChakraTh>
  );
};

export const EmptyHeadCell = (): JSX.Element => {
  return (
    <ChakraTh borderColor='gray.200' color='gray.500' textStyle='sm-normal' />
  );
};

export const Body = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <Tbody>{children}</Tbody>;
};

export const Row = ({
  children,
  onClick,
  isDisabled,
}: {
  onClick?: () => void;
  children: React.ReactNode;
  isDisabled?: boolean;
}): JSX.Element => {
  return (
    <Tr
      cursor={isDisabled ? 'default' : 'pointer'}
      overflow='hidden'
      onClick={onClick}
      _hover={!isDisabled && { bg: 'gray.50' }}
    >
      {children}
    </Tr>
  );
};

type TdProps = {
  children: React.ReactNode;
  isLast: boolean;
} & TableCellProps;

export const Cell = ({ children, isLast, ...rest }: TdProps): JSX.Element => {
  const borderStyle = isLast ? { borderColor: 'gray.200' } : { border: 'none' };

  return (
    <ChakraTd {...borderStyle} textStyle='sm-normal' color='gray.500' {...rest}>
      {children}
    </ChakraTd>
  );
};
