import React from 'react';
import { ColorSwatchIcon } from '@heroicons/react/solid';
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { stringToSameTypeColor, colorToSameTypeString } from '@utils';

type ColorInputProps = {
  onChange: (v: string) => void;
  value: string;
  name?: string;
};

export function ColorInput({
  value,
  onChange,
  name,
}: ColorInputProps): JSX.Element {
  const colorInputRef = React.useRef(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const newColor = stringToSameTypeColor(input) || input;
    onChange(colorToSameTypeString(newColor) || (newColor as string));
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const pasteInput = e.clipboardData.getData('text/plain');
    const pasteColor = stringToSameTypeColor(pasteInput) || pasteInput;
    const pasteColorString = colorToSameTypeString(pasteColor);
    if (pasteColorString) {
      e.preventDefault();
      onChange(pasteColorString);
    }
  };

  return (
    <InputGroup onPaste={handlePaste}>
      <InputLeftElement>
        <Box h='inherit' w='inherit' p='0.5px 0.5px 1px 1px'>
          <Box
            as='label'
            bg={value}
            w='100%'
            h='100%'
            borderTopLeftRadius='md'
            borderBottomLeftRadius='md'
            cursor='pointer'
            borderRight='1px solid'
            borderColor='gray.200'
          >
            <Input
              ref={colorInputRef}
              name={name}
              data-testid='color-picker'
              type='color'
              value={value}
              onChange={handleChange}
              visibility='hidden'
            />
          </Box>
        </Box>
      </InputLeftElement>

      <Input
        pl={12}
        name={name}
        textTransform='uppercase'
        type='text'
        value={value}
        onChange={handleChange}
      />

      <InputRightElement
        cursor='pointer'
        onClick={() => colorInputRef?.current.click()}
      >
        <Icon as={ColorSwatchIcon} color='gray.300' />
      </InputRightElement>
    </InputGroup>
  );
}
