import React from 'react';
import {
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
  FormLabel,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { stripProtocol } from '@utils';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTLDParser } from '@hooks/useTLDParser';
import { UrlInput } from './UrlInput';
import { RadioButtonCard, RadioGroup } from '@components/common';
import { SeparateHostInput } from './SeparateHostInput';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import type { ProdInstanceForm } from './CreateProdInstanceModal';

type HomeUrlFormProps = {
  authVersion?: number;
};

export function HomeUrlForm({ authVersion }: HomeUrlFormProps): JSX.Element {
  const { control, register, setValue, getValues } =
    useFormContext<ProdInstanceForm>();
  const { parse } = useTLDParser();
  register('isETLD');
  register('domain');

  const isETLD = useWatch({ control, name: 'isETLD' });
  const domain = useWatch({ control, name: 'domain' });
  const backendHostValue = useWatch({ control, name: 'backend_host' });

  const onHomeUrlBlur = () => {
    const tldResult = parse(getValues('home_url'));

    setValue(
      'api_subdomain',
      getValues('api_subdomain') || tldResult.subdomain,
    );
    setValue(
      'backend_host',
      tldResult.isETLD ? 'separate' : getValues('backend_host'),
    );
    setValue('home_url', stripProtocol(getValues('home_url')));
    setValue('domain', tldResult.domain);
    setValue('isETLD', tldResult.isETLD!);
  };

  return (
    <>
      <UrlInput onBlur={onHomeUrlBlur} />
      {authVersion === 1 ? (
        <>
          <FormLabel textStyle='md-medium' mt='4' mb='2'>
            Application backend host
            <Tooltip
              label='The host where the application’s backend is hosted'
              placement='bottom'
            >
              <span>
                <Icon
                  as={QuestionMarkCircleIcon}
                  color='gray.300'
                  ml={1}
                  boxSize='18px'
                  mt='-1px'
                />
              </span>
            </Tooltip>
          </FormLabel>
          <RadioGroup name='backend_host' control={control} defaultValue='same'>
            <RadioButtonCard
              value='same'
              label='Same host as Home host'
              hint={
                <Text as='span' textStyle='md-normal'>
                  Frontend and Backend run on the same host. Appropriate for
                  full-stack frameworks such as NextJS and Rails.
                </Text>
              }
              isDisabled={isETLD}
            >
              {isETLD ? (
                <Alert status='error'>
                  <AlertIcon />
                  <AlertDescription>
                    <Text as='span' textStyle='md-normal'>
                      For security reasons, Clerk does not work with application
                      backends hosted on the naked domain. Consider using the{' '}
                      <strong>www</strong> subdomain, or hosting your backend on
                      the <strong>api</strong> subdomain.
                    </Text>
                  </AlertDescription>
                </Alert>
              ) : null}
            </RadioButtonCard>
            <RadioButtonCard
              value='separate'
              label='Separate host'
              hint='Frontend and Backend run on separate hosts.'
            >
              {backendHostValue === 'separate' ? (
                <SeparateHostInput domain={domain} />
              ) : null}
            </RadioButtonCard>
          </RadioGroup>
        </>
      ) : null}
    </>
  );
}
