import React from 'react';
import {
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
  Icon,
  Text,
  FormControl,
} from '@chakra-ui/react';
import { useFormContext, useController } from 'react-hook-form';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

export const PasswordLengthLimit = ({
  passwordMinLength,
}: {
  passwordMinLength: number;
}): JSX.Element => {
  const { control } = useFormContext();

  const { field: passwordLengthLimit } = useController({
    name: 'password_settings.min_length',
    control,
  });

  return (
    <>
      <FormControl isInvalid={passwordMinLength < 8}>
        <NumberInput
          size='sm'
          defaultValue={8}
          min={8}
          max={100}
          // TODO: remove this when the BE sends the 8. Now it sends min_length: 0
          onBlur={() => passwordLengthLimit.onChange(8)}
          onChange={(_, value) => passwordLengthLimit.onChange(value || 0)}
          value={passwordMinLength || ''}
        >
          <NumberInputField
            borderRadius={4}
            _focus={{
              outline: 0,
            }}
          />
          <NumberInputStepper>
            <NumberIncrementStepper border='none'>
              <Icon as={ChevronUpIcon} boxSize={4} color='gray.400' />
            </NumberIncrementStepper>
            <NumberDecrementStepper border='none'>
              <Icon as={ChevronDownIcon} boxSize={4} color='gray.400' />
            </NumberDecrementStepper>
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <Text textStyle='sm-normal' color='gray.500' ml={2}>
        characters
      </Text>
    </>
  );
};
