import React from 'react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import type { ProdInstanceForm } from './CreateProdInstanceModal';

type SeparateHostInputProps = {
  domain: string;
};

export function SeparateHostInput({
  domain,
}: SeparateHostInputProps): JSX.Element {
  const {
    register,
    formState: { errors },
  } = useFormContext<ProdInstanceForm>();
  const error = errors.api_subdomain;

  return (
    <Box pt='2'>
      <FormControl isInvalid={!!error}>
        <InputGroup variant='addonAction' borderWidth='1px' borderRadius='md'>
          <Input
            autoFocus
            placeholder='api'
            textAlign='right'
            {...register('api_subdomain', {
              required: 'API subdomain is required',
            })}
            data-testid='separate-host-input'
          />
          <InputRightAddon>{`.${domain || 'example.com'}`}</InputRightAddon>
        </InputGroup>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    </Box>
  );
}
