import React from 'react';

import { Heading } from '@components/common';
import { Flex, Text } from '@chakra-ui/react';

export type HeaderProps = {
  description?: string | JSX.Element;
  title?: string | JSX.Element;
  avatar?: React.ReactNode;
};

const Header = ({ title, description, avatar }: HeaderProps): JSX.Element => {
  const headingMargin = description ? '2' : 0;
  return (
    <Flex>
      <Flex mr={3} alignItems='center'>
        {avatar}
      </Flex>
      <Flex
        maxWidth='70%'
        overflow='hidden'
        justifyContent='center'
        direction='column'
      >
        {title && (
          <Heading
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='nowrap'
            as='h1'
            mb={headingMargin}
          >
            {title}
          </Heading>
        )}
        {description && typeof description === 'string' && (
          <Text textOverflow='ellipsis' textStyle='md-normal' color='gray.500'>
            {description}
          </Text>
        )}

        {description && typeof description !== 'string' && <>{description}</>}
      </Flex>
    </Flex>
  );
};

export default Header;
