import React from 'react';
import { Flex, SimpleGrid, Spinner, useToast } from '@chakra-ui/react';
import { Application } from 'types';
import { ApplicationCard } from './ApplicationCard';
import { AddApplicationCard } from './AddApplicationCard';
import { Heading } from '@components/common';
import { useOrganization } from '@clerk/nextjs';
import {
  useDashboardSWR,
  useDashboardCRUD,
  useOrganizationSubscription,
} from '@hooks';
import {
  CheckoutStatusNotice,
  ErrorMessages,
} from '@components/planAndBilling/constants';
import { useAnalytics } from '@hooks/useAnalytics';

export function Applications(): JSX.Element {
  const { organization } = useOrganization();
  const { data: applications, isValidating } =
    useDashboardSWR<Application[]>('/v1/applications');
  const { mutate: mutateOrgPlan } = useOrganizationSubscription();
  const { page, track } = useAnalytics();

  React.useEffect(() => {
    void page('Dashboard_Applications', {
      objectLocation: 'Applications',
      domain: 'dashboard.clerk.com',
    });

    void track('Dashboard_Applications_Overview Viewed', {
      location: 'Applications',
      surface: 'Dashboard',
    });
  }, [page, track]);

  const { create } = useDashboardCRUD();

  const toast = useToast();

  const showToast = (
    type: 'error' | 'success' | 'warning',
    title: string | React.ReactNode,
    description?: string | React.ReactNode,
  ) => {
    toast({
      title: title,
      description: description,
      status: type,
      duration: 10000,
      isClosable: true,
      position: 'top-right',
    });
  };

  const spinner = (
    <Flex h='xl' justify='center' align='center'>
      <Spinner />
    </Flex>
  );

  const cards = (
    <Flex maxW='940px' flexDir='column' ml='auto' mr='auto'>
      <Heading mb={9}>Applications</Heading>
      <SimpleGrid gridTemplateColumns='repeat(3,1fr)' spacing={8}>
        <AddApplicationCard />
        {applications?.map(a => (
          <ApplicationCard application={a} key={a.id} />
        ))}
      </SimpleGrid>
    </Flex>
  );

  React.useEffect(() => {
    if (isValidating) {
      return;
    }

    const urlParams = new URLSearchParams(location.search);
    const currentCheckoutStatus = urlParams.get('checkout');
    urlParams.delete('checkout');
    const checkoutSessionId = urlParams.get('checkout_session_id');
    urlParams.delete('checkout_session_id');

    history.replaceState({}, '', window.location.origin);

    switch (currentCheckoutStatus) {
      case CheckoutStatusNotice.CANCELED:
        showToast('warning', 'Payment was cancelled');
        break;
      case CheckoutStatusNotice.SUCCESS:
        if (!checkoutSessionId) {
          return;
        }
        create(`/v1/organizations/${organization?.id}/refresh_payment_status`, {
          checkout_session_id: checkoutSessionId,
        })
          .then((app: any) => {
            void mutateOrgPlan(app?.subscription);
            showToast(
              'success',
              'Payment was successful',
              <>
                The plan for this organization has been changed to unlimited
                members.
              </>,
            );
          })
          .catch(err => {
            const msg =
              ErrorMessages[err?.globalErrors[0].code] ||
              'Something went wrong, please try again later.';
            showToast('error', msg);
          });
        break;
    }
  }, [isValidating, applications]);

  if (isValidating) {
    return spinner;
  }

  return cards;
}
