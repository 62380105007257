import React from 'react';
import { Button, Flex, VStack } from '@chakra-ui/react';
import { Modal } from '@components/common';
import { useFormContext } from 'react-hook-form';
import { Heading } from '@components/common';
import { PasswordMeterOptions } from './PasswordMeter';
import { PasswordLengthLimit } from './PasswordLengthLimit';
import { FormCheckbox, Checkbox } from '@components/common/Checkbox';

type PasswordConfigModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function PasswordConfigModal({
  isOpen,
  onClose,
}: PasswordConfigModalProps): JSX.Element {
  const { watch } = useFormContext();
  const specialChar = '\\ !"#$%&\'()*+,-./:;<=>?@[\\]^_`\\{|}~';
  const passwordSettings = watch('password_settings');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      size='2xl'
      title='Password policies'
      subtitle='Configure strength and complexity requirements for user passwords'
    >
      <Modal.Body>
        <Heading as='h2' size='sm'>
          Rules
        </Heading>
        <VStack align='flex-start' w='full' py='4' pl={0} spacing='4'>
          <Checkbox
            isChecked={true}
            pointerEvents='none'
            onChange={() => null}
            name='password_settings.min_length'
            label='Enforce minimum password length'
            description='Passwords must contain 8 or more characters. Select a higher value to increase the minimum length, up to 100 characters.'
          />
          <Flex alignItems='center' w='200px' minH={8} pl={9} mt={2}>
            <PasswordLengthLimit
              passwordMinLength={passwordSettings.min_length}
            />
          </Flex>

          <FormCheckbox
            name='password_settings.disable_hibp'
            label='Reject compromised passwords, powered by HaveIBeenPwned'
            subtitleLink='https://clerk.com/docs/security/password-protection'
          />

          <FormCheckbox
            name='password_settings.show_zxcvbn'
            label='Enforce minimum password strength, powered by zxcvbn'
            // description='Choose a minimum level of strength enforcement (powered by zxcvbn).'
          />

          <PasswordMeterOptions
            templateColumns='repeat(3, 1fr)'
            width='calc(100% - 60px)'
            ml='35px!important'
            gap={2}
            hasCheckedPasswordStrength={passwordSettings?.show_zxcvbn}
            learnMoreLink='https://clerk.com/docs/security/password-protection'
          />

          <FormCheckbox
            name='password_settings.require_lowercase'
            label='Require at least 1 lowercase character'
          />

          <FormCheckbox
            name='password_settings.require_uppercase'
            label='Require at least 1 uppercase character'
          />

          <FormCheckbox
            name='password_settings.require_numbers'
            label='Require at least 1 number'
          />

          <FormCheckbox
            name='password_settings.require_special_char'
            label='Require at least 1 special character'
            description={`Special characters include: ${specialChar}`}
          />
        </VStack>
      </Modal.Body>

      <Modal.Footer mt='6'>
        <Flex direction='row' w='full'>
          <Flex justify='space-between' w='full' flexDir='row-reverse'>
            <Button onClick={onClose}>Continue</Button>
          </Flex>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
