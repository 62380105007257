import React from 'react';
import { Todo, TodoIds } from './types';
import { OauthCredentials } from '../OauthCredentials';

export const installClerkTodo: Todo = {
  link: 'https://clerk.com/docs/quickstarts/get-started-with-create-react-app',
  linkText: 'Install Clerk',
  title: 'Install Clerk',
  content:
    'Get your Frontend API Key and follow the Get Started docs to add Clerk to your application',
  completed: false,
  id: TodoIds.Documentation,
  dismissed: false,
  target: '_blank',
};

export const userManagementTodo = (
  instanceId: string,
  applicationId: string,
): Todo => ({
  link: `/apps/${applicationId}/instances/${instanceId}/authentication/standard-form-fields`,
  linkText: 'Fine-tune user management',
  title: 'Fine-tune user management',
  content: 'Tweak your authentication strategies for sign up and sign in.',
  completed: false,
  id: TodoIds.UserManagement,
  dismissed: false,
  target: '_self',
});

export const themeTodo = (instanceId: string, applicationId: string): Todo => ({
  link: `/apps/${applicationId}/instances/${instanceId}/customization/theme`,
  linkText: 'Go to Settings › Theme',
  title: 'Customize theme',
  content:
    'Adjust the look and feel of Clerk Components to match your application',
  completed: false,
  id: TodoIds.Theme,
  dismissed: false,
  target: '_self',
});

export const oauthTodo = (instanceId: string, applicationId: string): Todo => ({
  link: `/apps/${applicationId}/instances/${instanceId}/user-authentication/social-connections`,
  linkText: 'Go to Authentication › Social Connections',
  title: 'Set Social Connection (OAuth) credentials',
  content: <OauthCredentials />,
  completed: false,
  id: TodoIds.OAuth,
  dismissed: false,
  target: '_self',
});

export const apiKeysTodo = (
  instanceId: string,
  applicationId: string,
): Todo => ({
  link: `/apps/${applicationId}/instances/${instanceId}/api-keys`,
  linkText: 'Set your API keys',
  title: 'Set your API keys',
  content:
    'Set your Frontend API key to your <ClerkProvider/> and your Backend API key to CLERK_API_KEY environment variable in your application',
  completed: false,
  id: TodoIds.ApiKeys,
  dismissed: false,
  target: '_self',
});

export const dnsTodo = (instanceId: string, applicationId: string): Todo => ({
  link: `/apps/${applicationId}/instances/${instanceId}/dns`,
  linkText: 'Go to Settings › DNS',
  title: 'Configure DNS records',
  content: 'Set up the necessary CNAME records before deploying',
  completed: false,
  id: TodoIds.DNS,
  dismissed: false,
  target: '_self',
});
